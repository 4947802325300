import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import MuiAlert from "@mui/material/Alert";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";

import ErrorMessage from "react-lib/apps/common/ErrorMessage";

// ---------- SnackMessage
type SnackMessageProps = {
  onEvent?: (e: any) => void;
  id?: any;
  autoHideDuration?: number;
  children?: ReactElement | boolean | string | null;
  error: any;
  header?: any;
  shouldAutoHide?: boolean;
  success: any;
  sx?: SnackbarProps["sx"];
  warning?: any;
  onClose?: () => void;
};

// const SnackMessageInitial: SnackMessageProps = {
//   error: null,
//   success: null,
//   warning: null,
//   onClose: null,
//   onEvent: () => null,
// };

const useStyles = makeStyles(() => ({
  box_snack_message: {
    "& .MuiAlert-icon": {
      alignItems: "center",
      borderRadius: "50rem",
      display: "flex",
      fontSize: "2.75rem",
      height: "1.8rem",
      justifyContent: "center",
      width: "1.8rem",
    },
    "& .MuiAlert-standardError": {
      "& .MuiAlert-icon": {
        background: "#f0555a",
      },
      "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit": {
        color: "rgb(253, 236, 234)",
      },
      color: "#f0555a !important",
    },
    "& .MuiAlert-standardSuccess": {
      "& .MuiAlert-icon": {
        background: "#67cf9d",
      },
      "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit": {
        color: "rgb(237, 247, 237)",
      },
      color: "#67cf9d !important",
    },
    "& .MuiPaper-root.MuiAlert-root": {
      "& .MuiAlert-message": {
        width: "100%",
      },
      borderRadius: "12px",
      boxShadow: "0 0 8px 1px rgba(133,133,133,0.1)",
      marginTop: "2px",
      minWidth: "285px",
      padding: "20px 20px",
      width: "100%",
    },
    "&.MuiSnackbar-root": {
      zIndex: 1001,
    },
  },
}));

const ANCHOR_ORIGIN: SnackbarProps["anchorOrigin"] = { horizontal: "center", vertical: "top" };

const SnackMessage = (props: SnackMessageProps) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<any>(null);

  useEffect(() => {
    if (
      props.error &&
      typeof props.error === "string" &&
      props.error.length > 1000 &&
      props.error.includes("Page not found")
    ) {
      setErrorMessage("API not found");
    } else if (Array.isArray(props.error)) {
      const errorData = props.error.map((e: string | undefined) => {
        if (e && e.length > 1000 && e.includes("Page not found")) {
          return "API not found";
        }

        return e;
      });

      setErrorMessage(errorData);
    } else {
      setErrorMessage(props.error);
    }
  }, [props.error]);

  const severity = useMemo(() => {
    if (props.error) {
      return "error";
    }

    if (props.warning) {
      return "warning";
    }

    return "success";
  }, [props.error, props.warning]);

  const sx = useMemo(
    () =>
      props.children
        ? {
            "& .MuiAlert-action": { marginTop: "0.8rem" },
            "& .MuiAlert-icon": { marginTop: "1.2rem" },
            alignItems: "unset",
          }
        : { alignItems: "center" },
    [props.children]
  );

  const handleCloseSnackbar = useCallback(
    (event: any, reason?: any) => {
      if (reason === "clickaway") {
        return;
      }

      if (props.onClose) {
        props.onClose();
      } else {
        props.onEvent?.({ message: "handleClearMessage" });
      }
    },
    [props.onClose]
  );

  return (
    <>
      {(!!props.error || !!props.success || !!props.warning) && (
        <Snackbar
          id={props.id ? `SnackBar-${props.id}` : `SnackBar-Message`}
          anchorOrigin={ANCHOR_ORIGIN}
          autoHideDuration={props.shouldAutoHide ?? true ? 3500 : null}
          className={classes.box_snack_message}
          sx={props.sx}
          open
          onClose={handleCloseSnackbar}
        >
          <MuiAlert
            id={props.id ? `MuiAlert-${props.id}` : `MuiAlert-Message`}
            elevation={4}
            severity={severity}
            sx={sx}
            onClose={handleCloseSnackbar}
          >
            <ErrorMessage
              id={props.id ? props.id : `SnackMessage`}
              error={errorMessage}
              header={props.header}
              success={props.success}
              warning={props.warning}
            />
            {props.children}
          </MuiAlert>
        </Snackbar>
      )}
    </>
  );
};

// SnackMessage.defaultProps = SnackMessageInitial;

SnackMessage.displayName = "SnackMessage";

export default React.memo(SnackMessage);
